import React from "react";
// import {Link} from 'gatsby';
import {Helmet} from 'react-helmet'

import Layout from "../components/layout.js"

const AboutPage=() => {
    return (
        <Layout>
            <Helmet>
                <title>Opony Tychy, Wulkanizacja Tychy, Wymiana opon Tychy - Oferta Serwisu KostaAuto</title>

                <link rel="canonical" href="https://oponytychy.pl/oferta" />
                <meta name="description" content="Oferta serwisu KostaAuto. Wulkanizacja, wymiana opon w Tychach. Opony letnie i opony zimowe do twojego samochodu. Wulkanizacja opon na zimno i gorąco. Wymieniamy opony z zimowych na letnie oraz z letnich na zimowe. Sprzedajemy opony letnie, opony, zimowe oraz opony wielosezonowe znanych światowych marek." />
                <meta name="keywords" content="zakup opon wielosezonowe, wymiana opon Tychy, sprzedaż opon" />
            </Helmet>


            <section id="content">
                <div className="indent-bottom">
                    <div className="main border-rad">
                        <div className="container_12">
                            <div>
                                <div className="grid_4 last-col">
                                    <div className="box1">
                                        <div className="indents5">
                                            <h2 className="p3-1"><span>Oferujemy:<strong></strong></span></h2>

                                            <ol className="list-1">
                                                <li><strong>Opony letnie</strong></li>
                                                <li><strong>Opony zimowe</strong></li>
                                                <li><strong>Wulkanizacja opon </strong>na zimno</li>
                                                <li><strong>Wulkanizacja opon</strong> na gorąco</li>
                                                <li><strong>Wymiana opon</strong></li>
                                                <li><strong>Sprzedaż opon</strong></li>
                                                <li><strong>Sprzedaż felg</strong></li>
                                                <li><strong>Komputerowe wyważanie k&oacute;ł</strong></li>
                                                <li><strong>Sezonowe przechowywanie opon</strong></li>
                                                <li><strong>Naprawa dętek</strong></li>
                                            </ol>

                                        </div>
                                    </div>
                                </div>
                                <div className="grid_8 last-col">
                                    <div className="box1">
                                        <div className="indents5">
                                            <h2 className="p4">O Nas:</h2>
                                            <p className="l_height p2 color-3">Firma Kosta Auto powstała w lipcu 1992 roku w Tychach z siedzibą przy ulicy Myśliwskiej 140. Od początku naszej działalności była firmą rodzinną. Od zawsze zajmowaliśmy się przekładkami opon letnich na opony zimowe i odwrotnie. Wiele lat doświadczenia w branży oponiarskiej oraz wiedza zdobyta na szkoleniach jest teraz do Państwa dyspozycji.</p>
                                            <p className="p2"><span className="color-2">Dzięki współpracy z hurtowniami opniarskimi jesteśmy wstanie zaproponować Państwu szeroki wachlarz opon letnich i opon zimowych uznanych światowych producentów. Jeśli jakaś opona nie jest dostępna z naszego magazynu jesteśmy wstanie ją sprowadzić w bazdzo krótkim czasie. O szczegóły pytaj w serwisie.</span>.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid_8 last-col">
                                    <div className="box1">
                                        <div className="indents5">
                                            <h2 className="p4">Zasady:</h2>
                                            <p className="l_height p2 color-3">Dbamy o wysoki poziom usług. Nasi pracownicy posiadają doskonałe   kwalifikacje i posiadają wiedzę zdobytą na szkoleniach potwierdzoną certyfikatami. Sprzęt używany w naszym serwisie należy do   najnowocześniejszych.</p>
                                            <p className="p2"><span className="color-2">Sprawą priorytetową zawsze pozostaje   bezpieczeństwo kierowców oraz ich pasażerów, dlatego przykładamy do tych kwestii szczególną uwagę. Wszystkie śruby k&oacute;ł dokręcamy kluczem dynamometrycznym.</span></p>
                                            <p className="p2"><span className="color-2">Nasz personel został przeszkolony w wulkanizacji opon i wulkanizacji dętek samochodów osobowych i dostawczych metodami na gorąco i zimno materiałami renomowanej firmy TIP TOP GmbH.</span></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AboutPage
